//MAIN WEBSITE ACTIONS
export const SUBMIT_STORY = 'SUBMIT_STORY';
export const GET_USER_STORIES = 'GET_USER_STORIES';

//DASHBOARD ACTIONS
export const APPROVE_STORY = 'APPROVE_STORY';
export const REJECT_STORY = 'REJECT_STORY';
export const DELETE_STORY = 'DELETE_STORY';
export const GET_PENDING_STORIES = 'GET_PENDING_STORIES';

//VOLUNTEER PLACES
export const GET_VOLUNTEER_PLACES = 'GET_VOLUNTEER_PLACES';

//API RESPONSE ACTIONS
export const SUBMIT_STORY_SUCCESS = 'SUBMIT_STORY_SUCCESS';
export const SUBMIT_STORY_FAILED = 'SUBMIT_STORY_FAILED';
export const GET_USER_STORIES_SUCCESS = 'GET_USER_STORIES_SUCCESS';
export const GET_USER_STORIES_FAILED = 'GET_USER_STORIES_FAILED';
export const GET_PENDING_STORIES_SUCCESS = 'GET_PENDING_STORIES_SUCCESS';
export const GET_PENDING_STORIES_FAILED = 'GET_PENDING_STORIES_FAILED';
export const GET_VOLUNTEER_PLACES_SUCCESS = 'GET_VOLUNTEER_PLACES_SUCCESS';
export const GET_VOLUNTEER_PLACES_FAILED = 'GET_VOLUNTEER_PLACES_FAILED';